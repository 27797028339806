// Generated by Framer (6bc8cd1)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, Image, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion } from "framer-motion";
import * as React from "react";

const cycleOrder = ["I467:1425;460:1920", "f2Qz0poBG", "oKewDytX0"];

const variantClassNames = {"I467:1425;460:1920": "framer-v-138bla0", f2Qz0poBG: "framer-v-d1knmk", oKewDytX0: "framer-v-1rodtuf"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const humanReadableVariantMap = {"Variant 1": "I467:1425;460:1920", "Variant 2": "f2Qz0poBG", "Variant 3": "oKewDytX0"};

const transitions = {default: {damping: 60, delay: 0, duration: 0.3, ease: [0.44, 0, 0.56, 1], mass: 1, stiffness: 500, type: "spring"}};


function toResponsiveImage_194x2gw(value) {
    if (typeof value === "object" && value !== null && typeof value.src === "string") {
        return value;
    };
    return typeof value === "string" ? {src: value} : undefined;
};


const BASE62 = "0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz"

function useRandomID(){const ref = React.useRef(null);
if (ref.current === null) {
ref.current = Array(5).fill(0).map(() => BASE62[Math.floor(Math.random() * BASE62.length)]).join("");
}
return ref.current;}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { layoutId?: string | null; width?: number; height?: number; layoutDependency?: string | number; variant?: keyof typeof humanReadableVariantMap; blogImageMobile?: {src: string; srcSet?: string} }

const Component = React.forwardRef<HTMLDivElement, Props>(function({ id, style: externalStyle = {}, className, width, height, layoutId, variant: outerVariant = "I467:1425;460:1920", blogImageMobile: H0jhrkjXH = {src: new URL("assets/1024/neGVsRcckpzSVKwksVJat9w1Po.jpg", import.meta.url).href, srcSet: `${new URL("assets/512/neGVsRcckpzSVKwksVJat9w1Po.jpg", import.meta.url).href} 512w, ${new URL("assets/1024/neGVsRcckpzSVKwksVJat9w1Po.jpg", import.meta.url).href} 1024w, ${new URL("assets/2048/neGVsRcckpzSVKwksVJat9w1Po.jpg", import.meta.url).href} 2048w, ${new URL("assets/neGVsRcckpzSVKwksVJat9w1Po.jpg", import.meta.url).href} 4096w`}, ...restProps }, ref) {

const outerVariantId = humanReadableVariantMap[outerVariant];

const variant = outerVariantId || outerVariant;

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "I467:1425;460:1920", transitions, variant, variantClassNames})
const layoutDependency = variants.join("-") + restProps.layoutDependency;

const defaultLayoutId = useRandomID()

const { pointerEvents, ...style } = externalStyle

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div data-framer-generated initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-JesmH", classNames)} style={{display: "contents", pointerEvents: pointerEvents ?? undefined}}>
<Image {...restProps} background={{alt: "", fit: "fill", ...toResponsiveImage_194x2gw(H0jhrkjXH)}} className={cx("framer-138bla0", className)} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"I467:1425;460:1920"} ref={ref} style={{...style}} transition={transition} {...addPropertyOverrides({f2Qz0poBG: {"data-framer-name": "Variant 2"}, oKewDytX0: {"data-framer-name": "Variant 3"}}, baseVariant, gestureVariant)}/>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-JesmH [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none;}", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-JesmH * { box-sizing: border-box; }", ".framer-JesmH .framer-138bla0 { height: 418px; overflow: hidden; position: relative; width: 569px; }", ".framer-JesmH.framer-v-d1knmk .framer-138bla0 { width: 369px; }", ".framer-JesmH.framer-v-1rodtuf .framer-138bla0 { width: 326px; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 418
 * @framerIntrinsicWidth 569
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"f2Qz0poBG":{"layout":["fixed","fixed"]},"oKewDytX0":{"layout":["fixed","fixed"]}}}
 * @framerVariables {"H0jhrkjXH":"blogImageMobile"}
 */
const FramerYE4F4IbTa: React.ComponentType<Props> = withCSS(Component, css) as typeof Component;
export default FramerYE4F4IbTa;

FramerYE4F4IbTa.displayName = "Blog Image";

FramerYE4F4IbTa.defaultProps = {height: 418, width: 569};

addPropertyControls(FramerYE4F4IbTa, {variant: {options: ["I467:1425;460:1920", "f2Qz0poBG", "oKewDytX0"], optionTitles: ["Variant 1", "Variant 2", "Variant 3"], title: "Variant", type: ControlType.Enum}, H0jhrkjXH: {__defaultAssetReference: "neGVsRcckpzSVKwksVJat9w1Po.jpg", title: "Blog Image Mobile", type: ControlType.ResponsiveImage}} as any)

addFonts(FramerYE4F4IbTa, [])